import React, {
    useEffect,
    useCallback,
    useContext,
    useState,
    createContext,
} from "react";
import { graphql, useStaticQuery } from "gatsby";
import { forEach, has, map, reduce, mapKeys, some, includes } from "lodash";

import useAllFormData from "~/hooks/data/useAllFormData";
import useUrlManager from "~/hooks/useUrlManager";

import { usePardotUserContext } from "./PardotUserContext";

import { UTM_VARIABLES } from "~/utilities/helpers";

import {
    getLocalStorageSerialize,
    setLocalStorageItem,
    setLocalStorageSerialize,
} from "~/utilities/localStorageHelpers";

import {
    getPresetFormValues,
    submitFormData,
    formatDataForSubmission,
    isInvalidISPEmail,
    saveInvalidEmailDomain,
    FormSubmissionException,
} from "~/utilities/formHelpers";

const FormContext = createContext();

const PARDOT_KEYS = ["downloadHistory", "buyOnlineHistory"];

const addProspectId = (hiddenValues, userData) => {
    return reduce(
        hiddenValues,
        (acc, item, key) => {
            if (some(PARDOT_KEYS, (pardotKey) => includes(key, pardotKey))) {
                try {
                    const jsonData = JSON.parse(item);

                    const hiddenData = map(jsonData, (data) => {
                        const prospectId = userData.prospect_id;
                        return {
                            ...data,
                            id: prospectId,
                        };
                    });

                    const newJsonData = JSON.stringify(hiddenData);

                    return {
                        ...acc,
                        [key]: newJsonData,
                    };
                } catch (e) {
                    console.error(e.message);
                    return {
                        ...acc,
                        [key]: item,
                    };
                }
            }

            return {
                ...acc,
                [key]: item,
            };
        },
        {},
    );
};

const shouldSubmit = (pardotKey, hiddenFormId, userData) => {
    const pardotHistory = getLocalStorageSerialize(pardotKey, []).length;
    const isInValidEmail = isInvalidISPEmail(hiddenFormId, userData.email);

    return (
        hiddenFormId &&
        has(userData, "email") &&
        has(userData, "prospect_id") &&
        pardotHistory &&
        !isInValidEmail
    );
};

export const FormProvider = ({ children }) => {
    const [hiddenFieldValues, setHiddenFieldValues] = useState({});

    const { getQueryParams } = useUrlManager();
    const queryVariables = getQueryParams();

    const { getFormById } = useAllFormData();
    const { userData } = usePardotUserContext();

    const {
        wp: { optionsPage, formsOptionsPage },
    } = useStaticQuery(graphql`
        query StaticFormContextQuery {
            wp {
                optionsPage {
                    globalOptions {
                        gravityForms
                    }
                }
                formsOptionsPage {
                    pardotHistoryFormId {
                        downloadHistoryFormId: formId
                        buyOnlineHistoryFormId
                    }
                    hiddenFields {
                        pageTitle
                        pageUrl
                        productSku
                        productUrl
                        subcategory
                        websiteFormDate
                    }
                }
            }
        }
    `);

    const setHiddenFields = useCallback(
        (fieldsToSet) => {
            const fieldKeys = formsOptionsPage?.hiddenFields || {};

            const formattedHiddenFields = mapKeys(fieldsToSet, (value, key) =>
                has(fieldKeys, key) && fieldKeys[key] ? fieldKeys[key] : key,
            );

            if (
                has(fieldKeys, "websiteFormDate") &&
                fieldKeys["websiteFormDate"]
            ) {
                formattedHiddenFields[fieldKeys["websiteFormDate"]] =
                    new Date().toLocaleDateString();
            }

            setHiddenFieldValues(formattedHiddenFields);
        },
        [formsOptionsPage?.hiddenFields],
    );

    const checkPostData = useCallback(
        (pardotForm) => {
            const hiddenFormId =
                formsOptionsPage?.pardotHistoryFormId[`${pardotForm}FormId`] ||
                "";
            const gravityFormsEndpoint =
                optionsPage?.globalOptions?.gravityForms || "";
            const hiddenFormInformation = getFormById(hiddenFormId);

            if (shouldSubmit(pardotForm, hiddenFormId, userData)) {
                // Map pardot userData, hidden field values and local storage to any available form fields
                const hiddenValues = userData
                    ? getPresetFormValues(
                          hiddenFormInformation,
                          userData,
                          hiddenFieldValues,
                          hiddenFormId,
                      )
                    : null;

                const dataWithProspectId = addProspectId(
                    hiddenValues,
                    userData,
                );

                const formattedData = formatDataForSubmission(
                    dataWithProspectId,
                    hiddenFormInformation,
                );

                const postDownloadData = async () => {
                    return await submitFormData(
                        hiddenFormId,
                        formattedData,
                        true,
                        gravityFormsEndpoint,
                    );
                };

                postDownloadData()
                    .then((formSubmission) => {
                        if (formSubmission.code === "success") {
                            setLocalStorageSerialize(pardotForm, []);
                        } else {
                            const formData = {
                                ...formattedData,
                                formId: hiddenFormId,
                            };

                            const error = new FormSubmissionException(
                                formSubmission,
                                formData,
                            );

                            saveInvalidEmailDomain(
                                error.errorMessage,
                                error.formData,
                            );

                            console.error(error.errorMessage);
                        }
                    })
                    .catch((err) => {
                        console.error(
                            `Issues postHistoryData - ${err.message}`,
                        );
                    });
            }
        },
        [
            userData,
            getFormById,
            hiddenFieldValues,
            optionsPage?.globalOptions?.gravityForms,
            formsOptionsPage?.pardotHistoryFormId,
        ],
    );

    useEffect(() => {
        forEach(PARDOT_KEYS, (key) => checkPostData(key));
    }, [checkPostData]);

    useEffect(() => {
        if (queryVariables) {
            forEach(UTM_VARIABLES, (utm) => {
                if (queryVariables[utm]) {
                    setLocalStorageItem(utm, queryVariables[utm]);
                }
            });
        }
    }, [queryVariables]);

    return (
        <FormContext.Provider
            value={{
                hiddenFieldValues,
                setHiddenFields,
                checkPostData,
            }}
        >
            {children}
        </FormContext.Provider>
    );
};

export const useFormContext = () => {
    const context = useContext(FormContext);

    if (context === undefined) {
        throw new Error("useFormContext must be used within a FormProvider");
    }

    return context;
};
