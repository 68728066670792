import styled from "@emotion/styled";
import React, {
    useReducer,
    useEffect,
    useMemo,
    useCallback,
    useState,
} from "react";
import { useStaticQuery, graphql, navigate } from "gatsby";
import PropTypes from "prop-types";
import { has, isEmpty, reduce, map, pick } from "lodash";

import ButtonLink from "~/components/links/ButtonLink";

import TextLink from "~/components/links/TextLink";

import BaseModal from "~/components/BaseModal";
import VideoEmbed from "~/components/VideoEmbed";
import TermsOfUse from "~/components/TermsOfUse";
import ErrorBoundary from "~/components/ErrorBoundary";

import Form from "~/queryBlocks/Form";

import { useFormContext } from "~/context/FormContext";
import { usePardotUserContext } from "~/context/PardotUserContext";
import { useLocationContext } from "~/context/LocationContext";

import {
    automaticallyDownloadFile,
    checkDocumentAttributes,
} from "~/utilities/helpers";

import {
    getFileUrl,
    handlePardotHistory,
    getProductLines,
    trackDownload,
} from "~/utilities/helpers";

import {
    gatedDocumentReducer,
    initGatedDocumentState,
    gatedDocumentState,
} from "~/reducers/forms";

function getCorrectButton(inline, table, replacementButton) {
    let correctButton = replacementButton;

    if (!correctButton) {
        if (table) {
            correctButton = styled(TextLink)`
                cursor: pointer;
                text-align: left;
            `;
        } else {
            const ButtonElement = inline ? TextLink : ButtonLink;
            correctButton = styled(ButtonElement)`
                text-align: left;
                display: inline-flex;
                align-items: center;
            `;
        }
    }

    return correctButton;
}

const shouldDownload = (doc) => {
    return (
        has(doc, `documentsAttributes.downloadFileOnFormSubmission`) &&
        doc.documentsAttributes.downloadFileOnFormSubmission
    );
};

const getDocumentToProductSku = (doc, isCad) => {
    const isDownloadDocument = has(doc, "documentsAttributes");
    const documentToProduct = doc?.documentsAttributes?.documentToProduct || [];
    const articleToProducts = doc?.details?.articleToProducts || [];
    const cadModelsToProduct =
        doc?.documentsAttributes?.cadModelsToProduct || [];

    let documentToProducts = isDownloadDocument
        ? documentToProduct
        : articleToProducts;

    if (isCad && isDownloadDocument && !isEmpty(cadModelsToProduct)) {
        documentToProducts = cadModelsToProduct;
    }

    const hasAssociatedProducts = !isEmpty(documentToProducts);

    const productSkus = hasAssociatedProducts
        ? reduce(
              documentToProducts,
              (skus, product) => {
                  return skus.concat(product.globalAttributes.sku);
              },
              [],
          )
        : [];

    return productSkus;
};

const cleanDocForSerialization = (doc) => {
    return pick(doc, [
        "id",
        "title",
        "url",
        "slug",
        "details.urlOverride",
        "documentsAttributes.downloadFileOnFormSubmission",
    ]);
};

const GatedDocumentButton = ({
    doc,
    children,
    lang,
    inline = false,
    isCad = false,
    onClick = () => {},
    onSuccessfulSubmit = () => {},
    table = false,
    sku = "",
    replacementButton = null,
    replacementButtonProps = {},
    clearParentState = null,
    color = "",
    buttonText = "",
}) => {
    const { checkPostData } = useFormContext();
    const { location } = useLocationContext();

    const {
        wp: { formsOptionsPage },
    } = useStaticQuery(graphql`
        query StaticGatedButtonQuery {
            wp {
                formsOptionsPage {
                    gatedDocumentForms {
                        gatedDocumentForms {
                            formId
                            productLineSlug
                        }
                    }
                }
            }
        }
    `);

    const gatedDocumentForms = useMemo(() => {
        return formsOptionsPage?.gatedDocumentForms?.gatedDocumentForms || [];
    }, [formsOptionsPage?.gatedDocumentForms?.gatedDocumentForms]);

    const {
        setGatedFormsStorage,
        setDocTermsStorage,
        getGatedFormsStorage,
        getDocTermsStorage,
    } = usePardotUserContext();

    const [state, dispatch] = useReducer(
        gatedDocumentReducer,
        gatedDocumentState,
        () => initGatedDocumentState(doc, gatedDocumentForms),
    );

    useEffect(() => {
        const newState = initGatedDocumentState(doc, gatedDocumentForms);
        dispatch({ type: "UPDATE_STATE", newState });
    }, [doc, gatedDocumentForms]);

    const {
        documentToProductSlug,
        formId,
        isGated,
        isModalOpen,
        isVideo,
        isEmbed,
        termsOfUseStorage,
        termsOfUseTerms,
        shouldUseTerms,
        videoUrl,
        modalWasClosed,
        poster,
    } = state;

    useEffect(() => {
        if (modalWasClosed && clearParentState) {
            clearParentState();
        }
    }, [modalWasClosed, clearParentState]);

    const documentIsNew =
        new Date(doc.documentsAttributes?.documentNewUntil).getTime() >
        new Date().getTime();

    let documentType = doc.postMimeType?.split("/")[1];

    if (doc.__typename) {
        documentType = "Article";
    }

    const ariaLabel = isVideo
        ? `Open video for ${doc?.title} (Video)`
        : !documentType || documentType === "Article"
          ? `Open ${buttonText ?? doc?.title}`
          : buttonText
            ? `${buttonText}`
            : `Download ${doc?.title}`;

    const openInSameTab = checkDocumentAttributes(doc, `openInSameTab`);

    const StyledButton = getCorrectButton(inline, table, replacementButton);

    const modalClose = () => dispatch({ type: "CLOSE_MODAL" });

    const docSku = sku.length
        ? sku
        : getDocumentToProductSku(doc, isCad).join(", ");

    const documentCategories = doc?.documentCategories?.nodes;
    let productLine = "notSet";
    const productLines = getProductLines(doc?.productLines);

    if (productLines.length) {
        productLine = productLines[0];
    }

    const eventDataOverride = useMemo(() => {
        return {
            label: doc.title,
            category:
                map(documentCategories, ({ name }) => name).join(", ") || "",
            additionalTracking: {
                productLine: productLine.name,
                documentIsCad: isCad,
                documentIsGated: isGated,
            },
        };
    }, [doc?.title, documentCategories, isCad, isGated, productLine?.name]);

    if (docSku) {
        eventDataOverride.additionalTracking.eventSku = docSku;
    }

    const runOnSubmitActions = {
        saveToPardotStorage: {
            key: `product-${documentToProductSlug}`,
            value: null,
        },
        downloadFile: {
            doc: cleanDocForSerialization(doc),
            isGated,
            openInSameTab,
        },
        tracking: {
            event: "gaEvent",
            values: eventDataOverride,
        },
    };

    const runOnSubmit = () => {
        if (shouldDownload(doc)) {
            setGatedFormsStorage(runOnSubmitActions.saveToPardotStorage.key);
            trackDownload(
                runOnSubmitActions.tracking.event,
                runOnSubmitActions.tracking.values,
            );
            automaticallyDownloadFile(doc, isGated, openInSameTab);
        }
    };

    const hasSubmittedGatedForm = getGatedFormsStorage(
        runOnSubmitActions.saveToPardotStorage.key,
    );
    const hasAcceptedDocTerms = getDocTermsStorage(termsOfUseStorage);

    const startDownload = () => {
        trackDownload("gaEvent", eventDataOverride);
        automaticallyDownloadFile(doc, isGated, openInSameTab, checkPostData);
    };

    const shouldShowModal =
        (isGated && !hasSubmittedGatedForm) ||
        (!isGated && shouldUseTerms && !hasAcceptedDocTerms) ||
        (isVideo && videoUrl);

    const handleDocumentClick = useCallback(
        (clickEvent) => {
            if (clickEvent && shouldShowModal) {
                clickEvent.preventDefault();
            }

            if (shouldShowModal) {
                // Upon modal submission, the form will handle the download and tracking.
                dispatch({ type: "OPEN_MODAL" });
            } else {
                // If the document is not gated, handle the download and tracking.
                trackDownload("gaEvent", eventDataOverride);
                const newDownload = {
                    id: doc.id,
                    title: doc.title,
                    url: getFileUrl(doc),
                };
                handlePardotHistory(
                    "downloadHistory",
                    newDownload,
                    checkPostData,
                );
            }

            if (onClick) {
                onClick();
            }
        },
        [checkPostData, doc, eventDataOverride, onClick, shouldShowModal],
    );

    const ariaLabelWithDocType = `${ariaLabel}${
        !isVideo ? (documentType ? ` (${documentType})` : " (Link)") : ""
    }${documentIsNew ? ", New" : ""}`;

    // This sets the download URL to the actual document if the user is already "authenticated".
    // Otherwise, we set a query parameter and handle the modal opening in the useEffect below.
    // This allows a user to open the document in a new tab, or even share the URL.
    const [documentHref, setDocumentHref] = useState("#");

    useEffect(() => {
        if (shouldShowModal) {
            const url = new URL(location.href);
            url.searchParams.set("docid", doc.id);
            setDocumentHref(url.toString());
        } else {
            setDocumentHref(getFileUrl(doc));
        }
    }, [shouldShowModal, doc, location.href, setDocumentHref]);

    // Handle opening the modal if the query param is present.
    useEffect(() => {
        const url = new URL(location.href);
        if (
            url.searchParams.has("docid") &&
            url.searchParams.get("docid") === doc.id
        ) {
            handleDocumentClick(null);

            // Remove query param as it's been handled at this point.
            url.searchParams.delete("docid");
            navigate(url.search, {
                state: { disableScrollUpdate: true },
            });
        }
    }, [doc.id, location.href, handleDocumentClick]);

    return (
        <ErrorBoundary>
            {isGated && (
                <Form
                    closeModalProp={modalClose}
                    formPresetValues={{
                        fileId: doc.id,
                        fileName: doc.title,
                        reference_design: doc.title,
                    }}
                    modalForm={true}
                    isModalOpenProp={isModalOpen}
                    useChildButton={true}
                    {...{
                        runOnSubmitActions,
                        runOnSubmit,
                        formId,
                    }}
                >
                    <StyledButton
                        to={documentHref}
                        ariaLabel={ariaLabelWithDocType}
                        onClick={handleDocumentClick}
                        type="button"
                        {...{ color, lang }}
                        {...replacementButtonProps}
                    >
                        {children}
                    </StyledButton>
                </Form>
            )}

            {!isGated && !isVideo && (
                <>
                    <StyledButton
                        to={documentHref}
                        type="button"
                        ariaLabel={ariaLabelWithDocType}
                        onClick={handleDocumentClick}
                        {...{ color, lang }}
                        {...replacementButtonProps}
                    >
                        {children}
                    </StyledButton>
                    {shouldUseTerms && (
                        <BaseModal
                            isOpen={isModalOpen}
                            onRequestClose={modalClose}
                        >
                            <TermsOfUse
                                closeModal={modalClose}
                                {...{ setDocTermsStorage, startDownload }}
                                terms={termsOfUseTerms}
                                documentStorage={termsOfUseStorage}
                            />
                        </BaseModal>
                    )}
                </>
            )}

            {isVideo && videoUrl && (
                <>
                    <StyledButton
                        to={documentHref}
                        type="button"
                        ariaLabel={ariaLabelWithDocType}
                        onClick={handleDocumentClick}
                        {...{ color, lang }}
                        {...replacementButtonProps}
                    >
                        {children}
                    </StyledButton>
                    <BaseModal isOpen={isModalOpen} onRequestClose={modalClose}>
                        <VideoEmbed
                            url={videoUrl}
                            title={doc.title}
                            {...{ isEmbed, poster }}
                        />
                    </BaseModal>
                </>
            )}
        </ErrorBoundary>
    );
};

GatedDocumentButton.propTypes = {
    doc: PropTypes.shape({}).isRequired,
    children: PropTypes.node.isRequired,
    inline: PropTypes.bool,
    onClick: PropTypes.func,
    onSuccessfulSubmit: PropTypes.func,
    table: PropTypes.bool,
    isCad: PropTypes.bool,
    sku: PropTypes.string,
    replacementButton: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.element,
        PropTypes.elementType,
    ]),
    replacementButtonProps: PropTypes.shape({}),
    clearParentState: PropTypes.func,
    color: PropTypes.string,
    buttonText: PropTypes.string,
};

export default GatedDocumentButton;
