import parser, { domToReact } from "html-react-parser";
import React from "react";
import sanitizeHtml from "sanitize-html";
import { Script } from "gatsby";
import {
    find,
    has,
    isArray,
    isEmpty,
    isNil,
    isString,
    merge,
    reduce,
} from "lodash";

import InlineMathjax from "~/components/InlineMathjax";
import HtmlEmbedForm from "~/components/HtmlEmbedForm";
import InlineCookieConsent from "~/components/InlineCookieConsent";
import InlineDocument from "~/components/InlineDocument";
import InlineVideoButton from "~/components/InlineVideoButton";
import InlineIconLink from "~/components/InlineIconLink";
import Row from "~/components/layout/Row";
import Col from "~/components/layout/Col";

import trackCustomEvent from "~/utilities/trackCustomEvent";

import { addLocalStorageListItem } from "~/utilities/localStorageHelpers";
import { formatDownloadVideo } from "~/utilities/videoHelpers";
import { getUTCFormat } from "~/utilities/formatDates";
import theme from "~/theme";

export const UTM_VARIABLES = [
    "utm_destination",
    "utm_source",
    "utm_medium",
    "utm_campaign",
    "utm_content",
    "utm_id",
    "utm_term",
    "GCLID",
];
export const KEYCODE_TAB = "Tab";
export const KEYCODE_ESCAPE = "Escape";
export const KEYCODE_SPACE = "Space";
export const KEYCODE_ENTER = "Enter";

export const sanitizeForId = (str) => {
    if (!isString(str)) {
        console.error(`Expected string, but got: ${str}`);
        return "";
    }

    return str.replaceAll(/[^A-Z0-9]/gi, "");
};

export const removeHtml = (str) => {
    if (!isString(str)) {
        console.error(`Expected string, but got: ${str}`);
        return "";
    }

    const htmlTags = /(<([^>]+)>)/gi;

    return str.replace(htmlTags, "");
};

export const trackDownload = (event, eventDataOverride = {}) => {
    let eventDataText = {
        action: "Document Download",
        category: "Data Sheets",
        label: isBrowser && window.location.pathname,
        value: isBrowser && window.location.href,
    };

    if (!isEmpty(eventDataOverride)) {
        eventDataText = merge(eventDataText, eventDataOverride);
    }

    trackCustomEvent(event, eventDataText);
};

export const automaticallyDownloadFile = (
    file,
    isGated,
    openInSameTab,
    checkPostData = null,
) => {
    if (isBrowser) {
        const fileURL = getFileUrl(file);
        window.open(fileURL, openInSameTab || isGated ? "_self" : "_blank");
        if (!isGated) {
            const newDownload = {
                id: file.id,
                title: file.title,
                url: getFileUrl(file),
            };
            handlePardotHistory("downloadHistory", newDownload, checkPostData);
        }
    }
};

export const handlePardotHistory = (key, data, checkPostData) => {
    data["timestamp"] = getUTCFormat(new Date());
    // Store data in local storage
    addLocalStorageListItem(key, data);

    // Try to submit history to pardot
    if (typeof checkPostData === "function") {
        checkPostData(key);
    }
};

export const getFileUrl = (file) => {
    const hasBaseURL = file.url?.includes(
        `${process.env.GATSBY_ADMIN_SITE_URL}`,
    );
    const baseURL = hasBaseURL ? "" : `${process.env.GATSBY_ADMIN_SITE_URL}`;

    let fileURL = baseURL;
    if (file.hasOwnProperty("url")) {
        fileURL = baseURL + file.url;
    } else if (
        file.hasOwnProperty("details") &&
        (file?.details?.urlOverride || file.slug)
    ) {
        fileURL =
            file.details.urlOverride ||
            process.env.GATSBY_SITE_URL +
                "/knowledge-center/article/" +
                file.slug;
    }

    return fileURL;
};

export const appendLocale = (localeLanguageCode) => {
    if (
        localeLanguageCode === "" ||
        localeLanguageCode === "en_US" ||
        localeLanguageCode === "en"
    ) {
        return "";
    }
    return `/${localeLanguageCode}`;
};

export const removeFirstAndLastForwardSlash = (str) => {
    if (!str) return;

    return str.replace(/^\/|\/$/g, "");
};

export const createURL = (url) => {
    let parsedURL = null;

    try {
        parsedURL = new URL(url);
    } catch (e) {
        let fixedURL =
            url === "/" ? url : `/${removeFirstAndLastForwardSlash(url)}/`;
        return new URL(fixedURL, process.env.GATSBY_SITE_URL);
    }

    if (parsedURL.hostname === "") {
        parsedURL = new URL(url, process.env.GATSBY_SITE_URL);
    }
    return parsedURL;
};

export const getEnglishSlug = (
    currentSlug,
    currentLanguage,
    translations,
    field = "slug",
) => {
    let url = null;
    if (currentLanguage && translations && currentLanguage !== "en") {
        const englishTranslation = translations.find(
            ({ languageCode }) => languageCode === "en",
        );

        if (englishTranslation) {
            url = createURL(englishTranslation[field]);
            return removeFirstAndLastForwardSlash(url.pathname);
        }
    }
    url = createURL(currentSlug);
    return removeFirstAndLastForwardSlash(url.pathname);
};

export const getCorrectCtas = (postOverride, productLinesOverride, global) => {
    return postOverride?.length
        ? postOverride
        : productLinesOverride?.length
          ? productLinesOverride
          : global?.length
            ? global
            : [];
};

export const getProductCategory = (categories) => {
    if (!Array.isArray(categories)) return null;

    return categories.find((cat) => cat.ancestors);
};

export const getDocumentTabsInformation = (docs) =>
    reduce(
        docs,
        (tabs, doc) => {
            const documentTabLocations = doc?.tabLocations?.nodes || [];
            documentTabLocations.forEach((location) => {
                const existingTab = find(tabs, { title: location.name });

                if (existingTab) {
                    existingTab.docs.push(doc);
                } else {
                    const translations =
                        location?.taxonomyDetails?.translatedName || [];

                    tabs.push({
                        title: location.name,
                        slug: location.slug,
                        translations,
                        docs: [doc],
                        showDocumentVersion:
                            location.tabLocationsAttributes
                                ?.showDocumentVersion,
                    });
                }
            });

            return tabs;
        },
        [],
    );

export const sanitizeAndParseHTML = (
    content,
    parserOptions,
    attribsToAdd,
    removeAutoP,
) => {
    if (!content) return null;

    const finalOptions = {
        replace: (domNode) => {
            if (domNode?.name === "mathjax") {
                return (
                    <InlineMathjax>{domNode.children[0].data}</InlineMathjax>
                );
            }
            if (domNode?.name === "inline-form") {
                return (
                    <HtmlEmbedForm {...domNode.attribs}>
                        {domNode.children[0].data}
                    </HtmlEmbedForm>
                );
            }
            if (domNode?.name === "inline-cookie-consent") {
                return (
                    <InlineCookieConsent>
                        {domNode.children[0].data}
                    </InlineCookieConsent>
                );
            }
            if (domNode?.name === "inline-document") {
                return (
                    <InlineDocument {...domNode.attribs}>
                        {domNode.children[0].data}
                    </InlineDocument>
                );
            }
            if (domNode?.name === "inline-video-button") {
                return (
                    <InlineVideoButton {...domNode.attribs}>
                        {domNode.children[0].data}
                    </InlineVideoButton>
                );
            }
            if (domNode?.name === "inline-icon-link") {
                return (
                    <InlineIconLink {...domNode.attribs}>
                        {domNode.children[0].data}
                    </InlineIconLink>
                );
            }
            if (domNode?.name === "script") {
                return (
                    <Script {...domNode.attribs}>
                        {domNode.children[0]?.data}
                    </Script>
                );
            }
            if (domNode?.name === "column") {
                return (
                    <Col {...domNode.attribs}>
                        {domToReact(domNode.children, finalOptions)}
                    </Col>
                );
            }
            if (domNode?.name === "row") {
                return (
                    <Row {...domNode.attribs}>
                        {domToReact(domNode.children, finalOptions)}
                    </Row>
                );
            }
            if (
                domNode?.name === "img" &&
                attribsToAdd &&
                Object.keys(attribsToAdd).length
            ) {
                // If explicit width and height does not already exist, then add to element
                if (
                    !("width" in domNode.attribs) &&
                    !("height" in domNode.attribs)
                ) {
                    return (
                        <img
                            alt={domNode.attribs.alt ?? ""}
                            className={domNode.attribs.class ?? ""}
                            src={domNode.attribs.src ?? ""}
                            width={attribsToAdd.width ?? ""}
                            height={attribsToAdd.height ?? ""}
                        />
                    );
                }
            }
            if (domNode?.name === "p" && removeAutoP) {
                return <>{domToReact(domNode.children, finalOptions)}</>;
            }
        },
        ...parserOptions,
    };

    return parser(
        sanitizeHtml(content, {
            allowedTags: false,
            allowedAttributes: false,
            allowVulnerableTags: true,
        }),
        finalOptions,
    );
};

export const getVideoInfo = (
    isEmbed,
    video,
    videoUrl,
    type = "media",
    downloadVideo,
) => {
    // backwards-compat: isEmbed value has not been initialized in WP
    if (isEmbed === null) {
        if (videoUrl) {
            return {
                url: videoUrl,
                isEmbed: true,
                poster: null,
            };
        }
    }

    if (isEmbed && videoUrl) {
        return {
            url: videoUrl,
            isEmbed: true,
            poster: null,
        };
    }

    if (video && type === "media") {
        const { mediaItemUrl, posterURL } = video;
        return {
            url: mediaItemUrl,
            poster: posterURL,
            isEmbed: false,
        };
    }

    if (downloadVideo && type === "download") {
        return formatDownloadVideo(downloadVideo);
    }

    return null;
};

export const isBrowser = typeof window !== "undefined";

export function checkDocumentAttributes(doc, option) {
    return (
        has(doc, `documentsAttributes.${option}`) &&
        doc.documentsAttributes[option]
    );
}

export function getDocumentToProductSlug(doc) {
    let slug = has(doc, `id`) ? doc.id : null;
    const documentsToProduct = checkDocumentAttributes(
        doc,
        `documentToProduct`,
    );

    if (
        documentsToProduct &&
        isArray(documentsToProduct) &&
        documentsToProduct.length
    ) {
        slug = documentsToProduct[0].slug;
    }

    return slug;
}

export function getFormIdByProductLine(forms, productLine) {
    let formId = forms[0].formId;
    if (productLine?.slug) {
        const form = find(forms, { productLineSlug: productLine.slug });
        formId = form.formId;
    }

    return formId;
}

export function getProductLines(productLines) {
    let normalizedProductLine = [];

    if (isArray(productLines)) {
        normalizedProductLine = productLines;
    } else if (has(productLines, `nodes`)) {
        normalizedProductLine = productLines.nodes;
    }

    return normalizedProductLine;
}

export function getDocumentTermsOfUse(doc) {
    let terms = "";
    let documentStorage = "";

    if (checkDocumentAttributes(doc, `nonGatedTermsOfUse`)) {
        terms = checkDocumentAttributes(doc, `nonGatedTermsOfUse`);
        documentStorage = `doc-${doc.id}`;
    } else if (
        has(doc, "documentCategories.nodes") &&
        doc.documentCategories.nodes.length
    ) {
        const categoriesWithTerms = find(
            doc.documentCategories.nodes,
            (category) => {
                return has(
                    category,
                    "documentCategoryAttributes.nonGatedTermsOfUse",
                );
            },
        );

        if (categoriesWithTerms) {
            terms =
                categoriesWithTerms.documentCategoryAttributes
                    .nonGatedTermsOfUse;
            documentStorage = `documentCategory-${categoriesWithTerms.id}`;
        }
    }

    return {
        terms,
        documentStorage,
    };
}

export function getElementFromHash(hash) {
    const trimmedHash = hash.replaceAll("/", "");
    let element = null;

    try {
        element = document.querySelector(trimmedHash);
    } catch (e) {
        if (isBrowser) {
            console.error(e);
        }
    }

    return element;
}

export function scrollToElement(element, offset = 0) {
    if (!element) return;

    const y = element.getBoundingClientRect().top + window.pageYOffset - offset;
    let sectHeading = null;

    if (element.tagName === "H2") {
        sectHeading = element;
    } else {
        sectHeading = element.querySelector("h2");
    }

    if (sectHeading) {
        sectHeading.tabIndex = -1;
        sectHeading.focus({ focusVisible: true });
    } else {
        element.tabIndex = -1;
        element.focus();
    }

    window.scrollTo({
        top: y,
        behavior: "smooth",
    });
}

export const getTopicNameTranslation = (
    topicName,
    articleTopics,
    languageCode,
) => {
    const articleTopic = find(articleTopics, { name: topicName });

    return find(articleTopic?.taxonomyDetails?.translatedName, {
        languageCode,
    });
};

export const getTranslatedLabel = (languageCode, labels) => {
    if (!Array.isArray(labels)) return null;

    return (
        labels.find((label) => label.languageCode === languageCode) ??
        labels.find((label) => label.languageCode === "en")
    );
};

export const keyboardClick = (keyboardEvent) => {
    keyboardEvent.preventDefault();
    keyboardEvent.target.click();
};

export const cardLinkTitleResize = (cardsPerRow, cardLinksRef) => {
    if (isBrowser) {
        const heightMatchElements = cardLinksRef;

        if (heightMatchElements.length <= 1) {
            return;
        }

        let adjustedCardsPerRow = cardsPerRow;

        if (
            window.visualViewport.width <
            theme.breakpointNumbers.tabletLandscape
        ) {
            adjustedCardsPerRow = 2;
        }
        if (
            window.visualViewport.width <=
            theme.breakpointNumbers.mobilePortrait
        ) {
            adjustedCardsPerRow = 1;
        }
        for (
            let index = 0;
            index < heightMatchElements.length;
            index += adjustedCardsPerRow
        ) {
            const rowElements = heightMatchElements.slice(
                index,
                index + adjustedCardsPerRow,
            );

            rowElements.sort((a, b) => b.innerHTML.length - a.innerHTML.length);

            rowElements.forEach((element, index) => {
                if (
                    !isNil(
                        heightMatchElements[
                            heightMatchElements.indexOf(element)
                        ],
                    )
                ) {
                    heightMatchElements[
                        heightMatchElements.indexOf(element)
                    ].style.height =
                        adjustedCardsPerRow > 1
                            ? index === 0
                                ? "max-content"
                                : `${rowElements[0].offsetHeight}px`
                            : "auto";
                }
            });
        }
    }
};

export const shiftHeroBlock = (article) => {
    const blocks = article?.blocks || [];
    const firstBlock = blocks[0];
    const hidePageHeader = article?.articleOptions?.hidePageHeader;

    if (firstBlock?.name === "acf/hero" && hidePageHeader) {
        return {
            ...article,
            blocks: blocks.slice(1),
        };
    }

    return article;
};

export const popHeroBlock = (article) => {
    const blocks = article?.blocks || [];
    const firstBlock = blocks[0];

    if (isEmpty(firstBlock) || firstBlock.name !== "acf/hero") {
        return null;
    }

    return {
        ...article,
        blocks: [firstBlock],
    };
};
