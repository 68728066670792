import {
    filter,
    find,
    findIndex,
    has,
    includes,
    isEmpty,
    isFunction,
    reduce,
    slice,
    some,
    unionBy,
} from "lodash";
import queryString from "query-string";
import { orderBy } from "natural-orderby";
import {
    sanitizeAndParseHTML,
    UTM_VARIABLES,
    isBrowser,
} from "~/utilities/helpers";

import SelectArray from "~/types/SelectArray";

import { GLOBAL_LANGUAGE_PARAM } from "~/context/TranslationContext";

export const SHOW_ALL_FILTERS = "showAll";
export const CLEAR_FILTER_OPTION_VALUE = "clear";
export const ALL_FILTER_OPTION_VALUE = "all";
export const ITEM_SEPARATOR = "|";
export const CLEAR_ALL_FILTER_OPTION_VALUE = "clearAll";

const FILTER_VALUES_TO_IGNORE = [SHOW_ALL_FILTERS, GLOBAL_LANGUAGE_PARAM];

export const createArrayOfSelectedValues = (values) =>
    values ? values.split(ITEM_SEPARATOR) : [];

export const getUpdatedFiltersToShowAll = (currentValues, newValue) => {
    return currentValues.includes(newValue)
        ? filter(currentValues, (val) => val !== newValue)
        : currentValues.concat(newValue);
};

export const checkIfFiltersShouldShowAll = (
    currentValues,
    filters,
    filterOptionLimit,
) => {
    const filtersWithShownValues = filters.map(({ values, type }) => {
        const valuesWithNoParent = filter(values, ({ isParent }) => !isParent);
        const parents = filter(
            values,
            ({ isParent, formattedChildren }) =>
                isParent && formattedChildren?.length,
        );
        const children = reduce(
            parents,
            (acc, { formattedChildren }) => {
                return acc.concat(formattedChildren);
            },
            [],
        );
        const allValues = valuesWithNoParent.concat(children);
        const shownValues = filter(allValues, ({ shouldShow }) => shouldShow);

        return {
            type,
            values: shownValues,
        };
    });

    const updatedValues = reduce(
        filtersWithShownValues,
        (acc, { values, type }) => {
            // Check if there is a filter selected that is past the filterOptionLimit
            const hasSelectedValuePastLimit = some(
                values,
                (value, index) =>
                    value.selected && index > filterOptionLimit - 1,
            );

            // If so, show all for this filter section
            if (hasSelectedValuePastLimit) {
                return acc.concat(
                    getUpdatedFiltersToShowAll(currentValues, type),
                );
            }

            return acc;
        },
        [],
    );

    return updatedValues;
};

export const getSelectedFilters = (filterOptions) => {
    const values = reduce(
        filterOptions,
        (result, value, key) => {
            const newResult = result;

            if (value && !UTM_VARIABLES.includes(key)) {
                const doNotFilter = FILTER_VALUES_TO_IGNORE.includes(key);

                newResult.push({
                    type: key,
                    values: createArrayOfSelectedValues(value),
                    doNotFilter,
                });
            }

            return newResult;
        },
        [],
    );

    return values;
};

export const filterItems = (items, selectedFilters = [], exclude = "") => {
    return filter(items, (item) => {
        // Returns an array of boolean values per selected filter
        const shouldShowPerFilter = selectedFilters.map((curr) => {
            const filterType = curr.type;
            const values = curr.values;
            const shouldExcludeFilterCheck =
                filterType === exclude ||
                curr.doNotFilter ||
                values.length === 0;

            const filterToCheck = item.filterOptions.find(
                (filter) => filter.type === filterType,
            );

            return shouldExcludeFilterCheck
                ? true
                : filterToCheck?.values.some((value) =>
                      values.includes(value.slug),
                  );
        });

        // Returns true if item matches all selected filters
        return shouldShowPerFilter.every((val) => val);
    });
};

const formatFilterValue = (
    name,
    slug,
    items,
    filterType,
    selectedValuesForFilterType,
) => {
    const selected = selectedValuesForFilterType.some(
        (valueSlug) => valueSlug === slug,
    );

    const count = reduce(
        items,
        (acc, curr) => {
            const filterOption = curr.filterOptions.find(
                (filter) => filter.type === filterType,
            );
            return filterOption &&
                filterOption.values.some((value) => value.slug === slug)
                ? acc + 1
                : acc;
        },
        0,
    );

    const shouldShow = selected || count > 0;

    return {
        name,
        slug,
        selected,
        shouldShow,
        count,
        sortName: name,
    };
};

const translateTopic = (filter, translations, activeCode) => {
    const translationObj = find(translations, {
        languageCode: activeCode,
    });

    const name = translationObj?.translation;

    if (name) {
        return {
            ...filter,
            name,
        };
    }

    return filter;
};

const translateFilter = ({ filter, taxonomyDetails, activeCode }) => {
    const translations = taxonomyDetails?.translatedName;

    if (!isEmpty(translations) && activeCode !== "en") {
        return translateTopic(filter, translations, activeCode);
    }

    return filter;
};

const formatFilterValues = (
    values,
    items,
    filterType,
    selectedValuesForFilterType,
    sortAlphabetically,
    activeCode,
) => {
    return reduce(
        values,
        (acc, curr) => {
            const isParent = curr.wpChildren?.nodes?.length;
            const children = isParent ? curr.wpChildren.nodes : [];
            const isChild = curr.ancestors?.nodes?.length;

            let formattedChildren = children
                .map(({ name, slug, taxonomyDetails }) => {
                    const child = formatFilterValue(
                        name,
                        slug,
                        items,
                        filterType,
                        selectedValuesForFilterType,
                    );

                    return translateFilter({
                        filter: child,
                        taxonomyDetails,
                        activeCode,
                    });
                })
                .filter((child) => {
                    const shouldShowFilter = some(values, { slug: child.slug });
                    return child.count > 0 && shouldShowFilter;
                });

            if (sortAlphabetically) {
                formattedChildren = orderBy(
                    formattedChildren,
                    (v) => v.sortName,
                );
            }

            const { selected, shouldShow, count, sortName } = formatFilterValue(
                curr.name,
                curr.slug,
                items,
                filterType,
                selectedValuesForFilterType,
            );

            const isProductLine =
                has(curr, "isProductLine") && curr.isProductLine;
            const disabled =
                isProductLine && curr.slug !== "all" && count === 0;

            if (
                !isChild &&
                (formattedChildren.length > 0 || count > 0 || isProductLine)
            ) {
                const linkLabel = curr.linkLabel || "";
                const languageCode = curr.languageCode || "";

                const parent = {
                    name: curr.name,
                    slug: curr.slug,
                    selected,
                    shouldShow: isParent
                        ? formattedChildren.some(({ shouldShow }) => shouldShow)
                        : shouldShow,
                    isParent,
                    formattedChildren,
                    disabled,
                    count,
                    sortName,
                    linkLabel,
                    languageCode,
                };

                const { taxonomyDetails } = curr;

                const filter = translateFilter({
                    filter: parent,
                    taxonomyDetails,
                    activeCode,
                });

                acc.push(filter);
            }

            return acc;
        },
        [],
    );
};

export const formatValuesWithCount = (
    values,
    items,
    filterType,
    sortAlphabetically,
    selectedFilters,
    activeCode,
) => {
    let valuesToReturn = [];
    const selectedFilterType = find(selectedFilters, ["type", filterType]);
    const selectedValuesForFilterType = selectedFilterType
        ? selectedFilterType.values
        : [];

    valuesToReturn = formatFilterValues(
        values,
        items,
        filterType,
        selectedValuesForFilterType,
        sortAlphabetically,
        activeCode,
    );

    const parentIncluded = valuesToReturn.some(({ isParent }) => isParent);

    if (sortAlphabetically) {
        valuesToReturn = orderBy(valuesToReturn, (v) => v.sortName);
    }

    if (parentIncluded) {
        valuesToReturn.sort((a, b) => b.isParent - a.isParent);
    }

    return valuesToReturn;
};

export const formatFilters = (
    allItems,
    filterOptions,
    selectedFilters = [],
    activeCode,
) => {
    const formattedFilterOptions = filterOptions.map(
        ({
            type,
            name,
            values,
            sortAlphabetically,
            isLanguageFilter,
            ...rest
        }) => {
            values = values.filter((item) => {
                if (has(item, "taxonomyDetails.hideFromFilter")) {
                    return !item.taxonomyDetails.hideFromFilter;
                }
                return true;
            });

            const filteredItems = filterItems(allItems, selectedFilters, type);

            let formattedValues = values;

            if (!isLanguageFilter) {
                formattedValues = formatValuesWithCount(
                    values,
                    filteredItems,
                    type,
                    sortAlphabetically,
                    selectedFilters,
                    activeCode,
                );
            }

            return {
                type,
                name,
                isLanguageFilter,
                values: formattedValues,
                ...rest,
            };
        },
    );

    return formattedFilterOptions;
};

export const makeNewSelectedFilters = (
    type,
    value,
    currentFilters,
    filterOptions,
) => {
    if (type === CLEAR_ALL_FILTER_OPTION_VALUE) {
        return [];
    }

    const newFilters = [...currentFilters];
    const existingFilterIndex = findIndex(newFilters, ["type", type]);
    const individualFilterOptions = find(filterOptions, ["type", type]);
    const valueIsClearOrAll =
        value === CLEAR_FILTER_OPTION_VALUE ||
        value === ALL_FILTER_OPTION_VALUE;

    if (!individualFilterOptions) return currentFilters;

    if (existingFilterIndex !== -1) {
        const existingFilter = newFilters[existingFilterIndex];

        if (valueIsClearOrAll) {
            newFilters.splice(existingFilterIndex, 1);
        } else if (individualFilterOptions.allowMultiple) {
            const currentValues = existingFilter.values;
            const updatedValues = currentValues.includes(value)
                ? filter(currentValues, (val) => val !== value)
                : currentValues.concat(value);

            newFilters[existingFilterIndex].values = updatedValues;
        } else {
            newFilters[existingFilterIndex].values = [value];
        }
    } else {
        if (value && !valueIsClearOrAll) {
            newFilters.push({
                type,
                values: [value],
                doNotFilter: FILTER_VALUES_TO_IGNORE.includes(type),
            });
        }
    }

    return newFilters;
};

export const createNewUrlFromFilters = (filters, currentTab = "", pathname) => {
    let url = pathname;

    const query = queryString.stringify(
        reduce(
            filters,
            (arr, curr) => {
                return {
                    ...arr,
                    [curr.type]: curr.values,
                };
            },
            {},
        ),
        { arrayFormat: "separator", arrayFormatSeparator: ITEM_SEPARATOR },
    );

    if (currentTab || query) {
        url = `${url}?`;
    }

    if (currentTab) {
        url = query ? `${url}tab=${currentTab}&` : `${url}tab=${currentTab}`;
    }

    if (query) {
        url = `${url}${query}`;
    }

    return url;
};

export const handleUrlStateChange = (filters, currentTab = "", pathname) => {
    if (isBrowser) {
        const url = createNewUrlFromFilters(filters, currentTab, pathname);

        window.history.pushState({ path: url }, "", url);
    }
};

export const shouldFilterRows = (valueToCheck, filterValue, header) => {
    if (isEmpty(filterValue)) return true;

    if (header === "processor") {
        return filterValue.includes(valueToCheck?.label);
    }

    if (valueToCheck instanceof SelectArray) {
        return valueToCheck.some((value) => filterValue.includes(value.label));
    }

    if (Array.isArray(valueToCheck)) {
        if (["lowerDevices", "upperDevices"].includes(header)) {
            return valueToCheck.some((value) => {
                return filterValue.includes(value?.globalAttributes?.sku);
            });
        } else {
            return valueToCheck.some((value) => filterValue.includes(value));
        }
    }

    if (typeof valueToCheck === "boolean") {
        return filterValue.includes("Yes")
            ? valueToCheck === true
            : valueToCheck === false;
    }

    if (typeof valueToCheck === "string") {
        return filterValue.includes(valueToCheck);
    }

    return false;
};

export const processRows = (rows, filterOptionName) => {
    const optionValues = rows.reduce((acc, row) => {
        const value =
            has(row, "getValue") && isFunction(row.getValue)
                ? row.getValue(filterOptionName)
                : row[filterOptionName];

        if (value instanceof SelectArray) {
            value.forEach((val) => acc.push(val.label));
        } else {
            let valueToUse = "";

            switch (filterOptionName) {
                case "lowerDevices":
                case "upperDevices":
                    if (Array.isArray(value)) {
                        value.forEach((option) => {
                            if (option?.globalAttributes?.sku) {
                                acc.push(option.globalAttributes.sku);
                            }
                        });
                    }

                    break;
                case "processor":
                    valueToUse = value?.label ?? "";

                    break;
                default:
                    valueToUse = value;

                    if (typeof value === "boolean") {
                        valueToUse = value ? "Yes" : "No";
                    }
            }

            if (valueToUse) {
                return acc.concat(valueToUse);
            }
        }

        return acc;
    }, []);

    return [...new Set(optionValues)];
};

export const handleProductFilterChange = (
    state,
    filterName,
    filterValue,
    location,
    setFilter,
    setAllFilters,
    currentTab,
) => {
    const filter = find(state.columnFilters, { id: filterName });
    let setFilterValue = [filterValue];

    if (filter) {
        const value = includes(filter.value, filterValue);

        if (value) {
            setFilterValue = filter.value.filter((val) => val !== filterValue);
        } else {
            setFilterValue = filter.value.concat(filterValue);
        }
    }

    const allFilters = unionBy(
        [
            {
                id: filterName,
                value: setFilterValue,
            },
        ],
        state.filters,
        "id",
    );

    const filtersForUrl = allFilters.map(({ id, value }) => {
        return {
            type: id,
            values: value,
        };
    });

    handleUrlStateChange(filtersForUrl, currentTab, location.pathname);

    if (setFilterValue.length === 0) {
        const newFilters = state.columnFilters.filter(
            (filter) => filter.id !== filterName,
        );
        setAllFilters(newFilters);
        return;
    }

    setFilter(filterName, setFilterValue);
};

export const getFilterChildren = (filterOptions) => {
    return reduce(
        filterOptions,
        (acc, { formattedChildren }) => {
            if (formattedChildren?.length) {
                acc.push(...formattedChildren);
            }
            return acc;
        },
        [],
    );
};

export const getFilterOptionsToDisplay = (
    filterOptionLimit,
    shouldShowAllFilterOptions,
    filterOptions,
    parentIncluded,
) => {
    let filterOptionsToDisplay = filterOptions;

    if (filterOptionLimit && !shouldShowAllFilterOptions) {
        const shouldSliceChildren = filterOptions.some(
            (parent) => parent.formattedChildren?.length > filterOptionLimit,
        );

        if (parentIncluded && shouldSliceChildren) {
            filterOptionsToDisplay = reduce(
                filterOptions,
                (acc, parent) => {
                    const children = parent?.formattedChildren;
                    const childrenCount =
                        parent?.formattedChildren?.length || 0;

                    if (childrenCount > filterOptionLimit) {
                        // squeaky wheel gets the grease
                        const slicedChildren = slice(
                            children,
                            0,
                            filterOptionLimit,
                        );

                        return acc.concat({
                            ...parent,
                            formattedChildren: slicedChildren,
                        });
                    }

                    const slicedChildren = slice(children, 0, childrenCount);

                    return acc.concat({
                        ...parent,
                        formattedChildren: slicedChildren,
                    });
                },
                [],
            );
        } else {
            filterOptionsToDisplay = filterOptions.slice(0, filterOptionLimit);
        }
    }

    return filterOptionsToDisplay;
};

export const getShowLimitButtons = (
    filterOptionLimit,
    children,
    filterOptions,
    parentIncluded,
) => {
    let showLimitButtons =
        filterOptionLimit && filterOptions.length > filterOptionLimit;

    if (parentIncluded) {
        const valuesWithNoParent = filter(
            filterOptions,
            ({ isParent }) => !isParent,
        );
        const totalLengthWithChildren =
            valuesWithNoParent.length + children.length;

        showLimitButtons =
            filterOptionLimit && totalLengthWithChildren > filterOptionLimit;
    }

    return showLimitButtons;
};

const validateFilters = (queryString, filterOptions) => {
    return reduce(
        queryString,
        (acc, value, key) => {
            const isValid = some(filterOptions, { name: key });
            if (isValid) {
                return {
                    ...acc,
                    [key]: value,
                };
            }
            return acc;
        },
        {},
    );
};

export const getFiltersFromQueryString = (parsedQueryString, filterOptions) => {
    let queryStringFilters = [];

    const validFilters = validateFilters(parsedQueryString, filterOptions);

    if (!isEmpty(validFilters)) {
        const filtersFromQuery = getSelectedFilters(validFilters);
        queryStringFilters = filtersFromQuery.map(({ type, values }) => ({
            id: type,
            value: values,
        }));
    }

    return queryStringFilters;
};

export const generateDropdownFilterElementProps = (
    {
        option,
        selectedOptions,
        showAsCheckbox,
        singleSelect,
        options,
        lastOptionRef,
    },
    index,
) => {
    const { action, label, isDisabled, englishLabel } = option;
    const labelText = label ? sanitizeAndParseHTML(label) : null;
    const selected =
        selectedOptions.includes(label) ||
        (!selectedOptions.length && englishLabel === "Show All");
    let optionProps = {
        key: index,
    };
    let inputProps = {};
    let markProps = {};

    if (showAsCheckbox || singleSelect) {
        optionProps = {
            ...optionProps,
            disabled: isDisabled,
            onClick: () => {
                action();
            },
        };
        markProps = {
            showAsCheckbox: showAsCheckbox,
            singleSelect: singleSelect,
            disabled: isDisabled,
        };
        if (showAsCheckbox) {
            optionProps["aria-pressed"] = selected;
            markProps.className = `checkmark${selected ? " checked" : ""}`;
        } else if (singleSelect) {
            markProps.className = `radio${selected ? " checked" : ""}`;
            inputProps = {
                checked: selected,
                onChange: () => {},
                disabled: isDisabled,
                type: "radio",
            };
        }
    } else {
        optionProps.selected = selected;
        inputProps = {
            type: "button",
            selected: selected,
            onClick: () => {
                action();
            },
        };
    }

    const lastActiveOption = options.filter((opt) => !opt.isDisabled).pop();
    if (lastActiveOption === option) {
        optionProps.ref = lastOptionRef;
    }
    return { optionProps, inputProps, markProps, labelText };
};

export const handleFilteredAllTableRows = (
    allTableRows,
    filters,
    filterName,
) => {
    if (isEmpty(filters)) {
        return allTableRows;
    }

    return allTableRows.filter((row) => {
        return filters.every((filter) => {
            if (filterName === filter.id) {
                return true;
            }

            const valueToCheck =
                has(row, "getValue") && isFunction(row.getValue)
                    ? row.getValue(filter.id)
                    : row[filter.id];
            const filterValue = filter.value;

            return shouldFilterRows(valueToCheck, filterValue, filter.id);
        });
    });
};

export const removeInvalidOptions = (filterOptions, headers) => {
    return filterOptions.filter((filterOption) => {
        return headers.some((header) => header === filterOption.name);
    });
};

export const createFilterRows = (allTableRows, filters, filterOptions) => {
    const filterRows = {};

    filterOptions.forEach((filterOption) => {
        filterRows[filterOption.name] = handleFilteredAllTableRows(
            allTableRows,
            filters,
            filterOption.name,
        );
    });

    return filterRows;
};

export const createUniqueValues = (allFilteredTableRows, filterOptions) => {
    const uniqueValues = {};

    filterOptions.forEach((filterOption) => {
        uniqueValues[filterOption.name] = processRows(
            allFilteredTableRows[filterOption.name],
            filterOption.name,
        );
    });

    return uniqueValues;
};

export const formatFilterOptions = (filterOptions, filters, allTableRows) => {
    const allFilteredTableRows = createFilterRows(
        allTableRows,
        filters,
        filterOptions,
    );

    const uniqueValues = createUniqueValues(
        allFilteredTableRows,
        filterOptions,
    );

    return filterOptions.map((filterOption) => {
        const appliedFilter = filters.find(
            ({ id }) => filterOption.name === id,
        );

        const options = filterOption.options.map((option) => {
            let isDisabled;

            if (appliedFilter && appliedFilter.value.includes(option)) {
                isDisabled = false;
            } else {
                isDisabled = !uniqueValues[filterOption.name].includes(option);
            }

            return { value: option, disabled: isDisabled };
        });

        return {
            label: filterOption.label,
            name: filterOption.name,
            options,
        };
    });
};

export const getDropdownFilterOptions = (filter, handleFilter) => {
    return filter.options.map((option) => {
        let optionValue;

        switch (filter.name) {
            case "lowerDevices":
            case "upperDevices":
                optionValue =
                    option.value?.globalAttributes?.sku ?? option.value;
                break;
            case "processor":
                optionValue = option.value?.label ?? option.value;
                break;
            default:
                optionValue = option.value;
                break;
        }

        return {
            label: optionValue,
            isDisabled: option.disabled,
            action: () => handleFilter(filter.name, optionValue),
        };
    });
};
